import React, { Component } from "react"
import { Link } from "gatsby"
import SEO from "../components/subcomponent/SEO"
import Layout from "../components/layout"


export default class Index extends Component{


  render() {
    return (
      <Layout {...this.props}>
        <SEO />
        <div id="promocontainer">
          <img src={require("../assets/landing-2/bg.jpg")} className="bg" alt="bg"/>
          <div id="container">
            <span>
              Un <span className="highlight">qui est-ce</span> basé sur des évènements historiques !
            </span>
            <div className="main-logo-container">
              <img src={require("../assets/landing-2/logo@2x.png")} className="logo" alt="bg"/>

              <div className="store-container ">
                <a href="https://play.google.com/store/apps/details?id=io.atlantide.ad&hl=fr&gl=US" target="_blank"><img src={require("../assets/landing-2/dl-android@2x.png")} className="store" alt="bg"/></a>
                <a href='https://apps.apple.com/fr/app/les-archives-disparues/id1531625128' target="_blank"><img src={require("../assets/landing-2/dl-iphone@2x.png")} className="store apple" alt="bg"/></a>
              </div>
            </div>

            <div className="phone-container">
              <img src={require("../assets/landing-2/iphone@2x.png")} className="iphone" alt="phone"/>
              <img src={require("../assets/landing-2/emma@2x.png")} className="emma" alt="emma"/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}