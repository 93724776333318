import React from "react"
import { withPrefix } from "gatsby-link"

  //require('../assets/landing/dependencies/bootstrap/css/bootstrap-grid.min.css');
  require('../assets/landing/dependencies/fontawesome/css/all.min.css');
  require('../assets/landing/dependencies/wow/css/animate.css');
  //require('../assets/landing/sass/app.scss');

  // require("../assets/style/reset.css");
  //require("../assets/style/game.scss");
  require("../assets/style/landing-2.scss");

export default class Layout extends React.Component {
  
  componentDidMount() {
    if (this.props.location && this.props.location.pathname !== withPrefix ("/game")){
      // STYLE
    } else {
    }
  }

  render() {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

